import type { LinkDescriptor } from '@remix-run/node';
import swiperStyleBundle from 'swiper/swiper-bundle.min.css';
import swiperStyle from 'swiper/swiper.min.css';
import globalStylesheetUrl from '~/styles/global.css';
import tailwindStylesheetUrl from '~/styles/tailwind.css';
import rdtStylesheet from 'remix-development-tools/stylesheet.css';

export const LINKS: LinkDescriptor[] = [
// Fonts
{
  as: 'font',
  rel: 'preconnect',
  href: 'https://fonts.googleapis.com',
  crossOrigin: 'anonymous'
},
{
  as: 'font',
  rel: 'preconnect',
  href: 'https://fonts.gstatic.com',
  crossOrigin: 'anonymous'
},
{
  as: 'font',
  rel: 'stylesheet',
  href: 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'
},
// Calendly widget
{
  rel: 'stylesheet',
  href: 'https://assets.calendly.com/assets/external/widget.css'
},
// Styles
{ as: 'style', rel: 'stylesheet', href: globalStylesheetUrl },
{ as: 'style', rel: 'stylesheet', href: tailwindStylesheetUrl },
{ as: 'style', rel: 'stylesheet', href: swiperStyle },
{ as: 'style', rel: 'stylesheet', href: swiperStyleBundle },
// Icons
{
  rel: 'icon',
  type: 'image/png',
  sizes: '16x16',
  href: '/images/favicon/favicon-16x16.png'
},
{
  rel: 'icon',
  type: 'image/png',
  sizes: '32x32',
  href: '/images/favicon/favicon-32x32.png'
},
{
  rel: 'apple-touch-icon',
  sizes: '180x180',
  href: '/images/favicon/apple-touch-icon.png'
},
// Logo
{
  rel: 'preload',
  as: 'image',
  href: '/images/logo/creatordao_black_logo.svg'
},
...(rdtStylesheet ? [{ rel: 'stylesheet', href: rdtStylesheet }] : [])];