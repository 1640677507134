import * as React from 'react';
import { useTypedFetcher } from 'remix-typedjson';
import type { loader } from '~/root';
import { URLS } from '../constants/urls';

enum Theme {
    DARK = 'dark',
    LIGHT = 'light',
}
const themes: Array<Theme> = Object.values(Theme);

type ThemeContextType = [
    Theme | null,
    React.Dispatch<React.SetStateAction<Theme | null>>
];

const ThemeContext = React.createContext<ThemeContextType | undefined>(
    undefined
);

const prefersDarkMQ = '(prefers-color-scheme: dark)';
const getPreferredTheme = () =>
    window.matchMedia(prefersDarkMQ).matches ? Theme.DARK : Theme.LIGHT;

function ThemeProvider({
    children,
    specifiedTheme,
}: {
    children: React.ReactNode;
    specifiedTheme: Theme | null;
}) {
    const [theme, setTheme] = React.useState<Theme | null>(() => {
        // On the server, if we don't have a specified theme then we should
        // return null and the clientThemeCode will set the theme for us
        // before hydration. Then (during hydration), this code will get the same
        // value that clientThemeCode got so hydration is happy.
        if (specifiedTheme) {
            if (themes.includes(specifiedTheme)) {
                return specifiedTheme;
            } else {
                return null;
            }
        }

        // there's no way for us to know what the theme should be in this context
        // the client will have to figure it out before hydration.
        if (typeof document === 'undefined') {
            return null;
        }

        return getPreferredTheme();
    });

    const persistTheme = useTypedFetcher<typeof loader>();
    // TODO: remove this when persistTheme is memoized properly
    const persistThemeRef = React.useRef(persistTheme);
    React.useEffect(() => {
        persistThemeRef.current = persistTheme;
    }, [persistTheme]);

    const mountRun = React.useRef(false);

    React.useEffect(() => {
        if (!mountRun.current) {
            mountRun.current = true;
            return;
        }
        if (!theme) {
            return;
        }

        persistThemeRef.current.submit(
            { theme },
            { action: URLS.ACTION.SET_THEME, method: 'post' }
        );
    }, [theme]);

    React.useEffect(() => {
        const mediaQuery = window.matchMedia(prefersDarkMQ);
        const handleChange = () => {
            setTheme(mediaQuery.matches ? Theme.DARK : Theme.LIGHT);
        };
        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    return (
        <ThemeContext.Provider value={[theme, setTheme]}>
            {children}
        </ThemeContext.Provider>
    );
}

function useTheme() {
    const context = React.useContext(ThemeContext);
    if (context === undefined) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
}

function isTheme(value: Theme): value is Theme {
    return typeof value === 'string' && themes.includes(value as Theme);
}

export { isTheme, Theme, ThemeProvider, useTheme };
