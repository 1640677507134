import { Suspense, lazy, useEffect } from 'react';
import type { LinksFunction, LoaderArgs, MetaFunction } from '@remix-run/node';
import {
    Links,
    LiveReload,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useLocation,
} from '@remix-run/react';
import { withSentry } from '@sentry/remix';
import { ToastContainer } from 'react-toastify';
import { typedjson, useTypedLoaderData } from 'remix-typedjson';
import { ConfirmationModalProvider } from './common/components';
import { LINKS } from './common/constants/links';
import {
    LocaleContextProvider,
    useWindowLocales,
} from './common/providers/LocaleProvider';
import { Theme, ThemeProvider } from './common/providers/ThemeProvider';
import { cn } from './common/utils/styles';
import { getThemeSession, getUser } from './session.server';
import * as gtag from './common/utils/gtags.client';

const RemixDevTools =
    process.env.NODE_ENV === 'development'
        ? lazy(() => import('remix-development-tools'))
        : undefined;

export const links: LinksFunction = () => {
    return LINKS;
};

export const meta: MetaFunction = () => ({
    charset: 'utf-8',
    title: 'CreatorDAO',
    viewport: 'width=device-width,initial-scale=1',
    description:
        'The offical website of CreatorDAO, the decentralized community that accelerates creators with capital, technology, and operational services.',
    'theme-color': '#FFFFFF',
});

export async function loader({ request }: LoaderArgs) {
    const user = await getUser(request);
    const themeSession = await getThemeSession(request);
    return typedjson({
        user,
        ENV: {
            FIREBASE_CLIENT_JSON: process.env.FIREBASE_CLIENT_JSON,
            SENTRY_DSN: process.env.SENTRY_DSN,
            ALCHEMY_ID: process.env.ALCHEMY_ID,
            POSTHOG_KEY: process.env.POSTHOG_KEY,
            POSTHOG_HOST: process.env.POSTHOG_HOST,
            GOOGLE_ANALYTICS_TRACKING_ID:
                process.env.GOOGLE_ANALYTICS_TRACKING_ID,
        },
        theme: themeSession.getTheme(),
    });
}

function Head() {
    return (
        <head>
            <Meta />
            <meta charSet="utf-8" />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1"
            />
            <Links />
        </head>
    );
}

function App() {
    const { theme, ENV } = useTypedLoaderData<typeof loader>();
    const locales = useWindowLocales();
    const location = useLocation();

    useEffect(() => {
        if (ENV.GOOGLE_ANALYTICS_TRACKING_ID?.length) {
            gtag.pageview(location.pathname, ENV.GOOGLE_ANALYTICS_TRACKING_ID);
        }
    }, [location, ENV.GOOGLE_ANALYTICS_TRACKING_ID]);

    return (
        <html
            lang="en"
            className={cn('h-full', { [`dark`]: theme === Theme.DARK })}
        >
            <Head />
            <body className="h-full">
                {process.env.NODE_ENV === 'development' ||
                !ENV.GOOGLE_ANALYTICS_TRACKING_ID ? null : (
                    <>
                        <script
                            async
                            src={`https://www.googletagmanager.com/gtag/js?id=${ENV.GOOGLE_ANALYTICS_TRACKING_ID}`}
                        />
                        <script
                            async
                            id="gtag-init"
                            dangerouslySetInnerHTML={{
                                __html: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());

                            gtag('config', '${ENV.GOOGLE_ANALYTICS_TRACKING_ID}', {
                            page_path: window.location.pathname,
                            });
                        `,
                            }}
                        />
                    </>
                )}
                <LocaleContextProvider locales={locales}>
                    <ThemeProvider specifiedTheme={theme}>
                        <ToastContainer
                            position="top-center"
                            autoClose={4000}
                            hideProgressBar={true}
                            theme="dark"
                        />
                        <ConfirmationModalProvider>
                            <Outlet />
                        </ConfirmationModalProvider>
                    </ThemeProvider>
                </LocaleContextProvider>
                <ScrollRestoration />
                <Scripts />
                <LiveReload />
                {RemixDevTools && (
                    <Suspense>
                        <RemixDevTools />
                    </Suspense>
                )}
                <script
                    dangerouslySetInnerHTML={{
                        __html: `window.ENV = ${JSON.stringify(ENV)}`,
                    }}
                />
            </body>
        </html>
    );
}

export default withSentry(App);
